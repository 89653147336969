import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import SEO from "components/seo";

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: ${({
    theme: {
      layout: { sideMarginInnerDesktop },
    },
  }) => `5rem ${sideMarginInnerDesktop}`};
  @media (max-width: 768px) {
    padding: ${({
      theme: {
        layout: { sideMarginOuterMobile },
      },
    }) => `5rem ${sideMarginOuterMobile}`};
  }
  background-color: ${_ => _.theme.colors.blueishGray};
  hyphens: auto;

  a {
    color: ${_ => _.theme.accentGreen};
  }
  li {
    color: ${_ => _.theme.textSecondary};
  }
`;

export default function MardownTemplate({ data }) {
  useEffect(() => {
    // ensure correct hyphenation
    document.documentElement.setAttribute("lang", "de");
  }, []);
  const markdownRemark = data.markdownRemark;
  const { frontmatter, html } = markdownRemark;
  return (
    <>
      <SEO title={frontmatter && frontmatter.title} />
      <Wrapper dangerouslySetInnerHTML={{ __html: html }} />
    </>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
